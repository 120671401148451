@mixin StoryLayout {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    // translate: -50% 0;
    img {
        // display: inline-block;
    }
    .item {
        // flex-grow: 1;
        display: flex;
        @media (max-width: 500px) {
            flex-direction: column;
        }
        // border-top: 2px solid black;
        // min-width: 100vw;
        // display: inline;
        // flex-direction: column;
        padding: 10px 0px 0px 0px;
        .imgContainer {
            min-width: 150px;
            display: flex;
            justify-content: center;
            padding-right: 28px;
            img {
                align-self: flex-start;
                // padding-right: 10px;
                // height: 227px;
                max-width: 150px;
                display: block;
                margin: 0 auto;
                // position: absolute;
            }
            &.mobileFlex {
                header {
                    padding-left: 10px;
                }
            }
        }
        .floatBox {
            flex-grow: 1;
            header {
                display: flex;
                flex-direction: column;
                width: 233px;
                height: fit-content;
                translate: 0 20px;
                padding: 10px;
                padding-left: 0px;
                margin-right: 30px;
                padding-bottom: 10px;
                margin-bottom: 10px;

                float: left;
                @media screen and (max-width: 480px) {
                    margin-bottom: 25px;
                }
                // position: absolute;

                &.noImg {
                    width: 100%;
                    max-width: calc(
                        233px + 150px + 28px
                    );
                }
                h2,
                p {
                    display: inline;
                    margin: 0;
                }
                p {
                    white-space: no-wrap;
                }
                .imageContainer {
                    // position: absolute;
                    float: left;
                    // min-width: 180px;
                    height: 100%;
                    z-index: -1;
                    padding-right: 10px;
                }

                // align-items: center;
                // justify-content: space-between;
            }
            > p {
                min-height: 75px;
                margin-bottom: 0;
            }
        }
    }
    .buttons {
        // width: 100%;
        margin-top: 16px;
        text-align: left;
        padding: 0px 0 0 30px;
        button {
            a {
                text-decoration: none;
            }
        }
        .storyBtn {
            cursor: pointer;
            border-radius: 4px;
            padding: 3px 6px;
            background-color: transparent;
            color: black;
            border: 2px solid rgb(179, 142, 142);
            margin: 5px 10px 5px 0px;
            // box-shadow: 0 8px 16px 0
            //         rgba(0, 0, 0, 0.2),
            //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
            a {
                color: black;
                padding: 5px 7px;
            }
        }
    }
}
