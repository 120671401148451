@import "../../../mixins/CardStyle.scss";
ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
    &:not(.dash) {
        li {
            @include StoryLayout();
            .shareContainer {
                position: absolute;
                right: 10px;
                cursor: pointer;
                opacity: 0;
                transition: all 0.5s;
                display: flex;
                align-items: center;
                .share {
                    margin-left: 10px;
                }
                .copyNote {
                    display: none;
                }
                &.active {
                    display: block;
                    .copyNote {
                        display: inline-block;
                        translate: 0px -8px;
                    }
                    // display: none;
                }
            }
            &:hover {
                .shareContainer {
                    // display: block;
                    opacity: 1;
                    cursor: pointer;

                    .copyNote {
                        display: none;
                    }
                    &.active {
                        .copyNote {
                            translate: 0px -8px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
