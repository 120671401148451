header.mainHeader {
    box-shadow: -3px -22px 5px black,
        3px -22px 5px black;
}

#header {
    text-align: center;
    // position: absolute;
    // color: blue;

    div {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    h1 {
        width: fit-content;
        cursor: pointer;
        margin-bottom: 0;
    }
    p {
        margin-top: 0;
    }
    .websiteBtns {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        flex-direction: row;
        .websiteBtn {
            cursor: pointer;
            margin-inline: 10px;
        }
    }
    .filter {
        position: absolute;
        top: 50px;
        cursor: pointer;
        right: 30px;
        // translate: 100px 0;
    }
}
.headerBox {
    position: relative;
    min-height: 110px;
    .editWebsiteBtn {
        position: absolute;
        right: -30px;
        top: 10px;
        opacity: 0;
    }
    &:hover {
        .editWebsiteBtn {
            opacity: 1;
        }
    }
    * {
        outline: none;
    }
    &.isEditing {
        background-color: lightblue;
        padding-inline: 20px;
    }
}
