#about,
.donate {
    height: 100%;
    min-height: 100%;
    .promo {
        margin-top: auto;
    }
    .contentBox {
        position: relative;
        .editWebsiteBtn {
            position: absolute;
            top: 0;
            right: 10px;
            opacity: 0;
        }
        &:hover {
            .editWebsiteBtn {
                opacity: 1;
            }
        }
    }
}
