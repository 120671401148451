footer {
    // flex-grow: 1;
    margin-top: auto;
    text-align: center;
    justify-self: flex-end;
    // margin-bottom: 10px;
    // padding-top: 100px;
    height: auto;
    min-height: 100px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background: linear-gradient(
        180deg,
        transparent 0%,
        rgb(228, 222, 222) 100%
    );
    // background-image: radial-gradient(
    //     circle at bottom,
    //     #ffa384,
    //     transparent 100%
    // );
    // overflow: visible;
    position: relative;
    .promoContainer {
        // min-width: 70vw;
        // white-space: nowrap;
        border: 1px solid black;
        // max-width: fit-content;
        margin: 0 auto 00px;
        padding: 7px 12px;
        // position: absolute;
        // text-align: center;
        display: flex;
        flex-direction: column;
        // top: -30px;
        // left: 50%;
        // translate: -50% 0;
        scale: 0.8;
        p {
            margin: 0;
            line-height: 30px;
        }
    }
    a {
        font-family: inherit;
        &:not(.promo) {
            padding-inline: 10px;
            margin-inline: 10px;
        }
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .footerContainer {
        position: relative;
        .editWebsiteBtn {
            right: -50px;
            opacity: 0;
        }
        &:hover {
            .editWebsiteBtn {
                opacity: 1;
            }
        }
        .headerBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                margin: 0;
                line-height: 30px;
                scale: 0.7;
                letter-spacing: 1px;
                word-wrap: none;
            }
        }
    }
}
