.create {
    margin: 20px auto;
    width: 100%;
    min-height: 100px;
    max-width: 700px;
    display: flex;
    .formArea {
        flex-grow: 1;
    }
    .imgContainer {
        margin-right: 10px;

        height: fit-content;
        position: relative;
        // display: flex;
        min-width: 150px;
        img {
            max-width: 150px;
            display: block;
            margin: 0 auto;
        }
        h3 {
            position: absolute;
        }

        .plus {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            color: rgb(216, 193, 193);
        }
        .deleteImg {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 5px;
            z-index: 1;
        }

        &:hover {
            &::before {
                content: "";
                width: 60px;
                height: 60px;
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                background-color: rgba(
                    0,
                    0,
                    255,
                    0.33
                );
                border-radius: 50%;
            }
            .plus,
            .deleteImg {
                opacity: 1;
            }
        }

        &.isEditing {
            cursor: pointer;
            img {
                align-self: flex-start;
                max-width: 150px;
            }
        }
    }
    input[type="date"] {
        float: right;
    }
    .note-editor {
        margin: 20px 0;
    }
    textarea {
        display: block;
        margin: 20px 0;
        width: 100%;
        min-height: 100px;
        max-width: 700px;
        resize: vertical;
        box-sizing: border-box;
        padding: 4px;
        // padding-inline-start: 5px;
        // padding-inline-end: px;
    }
    .links {
        display: flex;
        align-items: center;
        .add {
            font-size: 50px;
            margin: 0 20px;
            cursor: pointer;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
            user-select: none;

            &:hover {
                box-shadow: 0px 0px 3px black;
            }
        }
        .addLinkContainer {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin-bottom: 10px;
            input {
                margin-top: 10px;
            }
        }
    }
    button {
        margin-right: 20px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
        &.add {
            padding: 0;
            border: none;
            // align-items: center;
        }
    }
    .newButtons {
        height: 105px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .btnContainer {
            display: flex;
            position: relative;

            .delBtn {
                display: none;
            }
            &:hover {
                .delBtn {
                    display: block;
                    position: absolute;
                    right: 2px;
                    top: 13px;
                    cursor: pointer;
                }
            }
        }
    }
}
