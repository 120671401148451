.storyPage {
    position: relative;
    .draftBtns {
        position: absolute;
        top: -80px;
        left: 25px;

        button {
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .delDocBtn {
        position: fixed;
        bottom: 10px;
        z-index: 3;
        right: 10px;
        background-color: rgb(223, 125, 125);
        border-style: dashed;
        cursor: pointer;
    }
}
