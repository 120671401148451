// @font-face {
//     font-family: "BellMT";
//     src: local("BellMT"),
//         url(/fonts/BellMT.ttf) format("truetype");
// }
body {
    margin: 0;
    font-family: "Libre Baskerville",
        "Abyssinica SIL", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    a {
        font-family: "BellMT";
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App {
    /* flex-grow: 1;
    display: flex; */
    p {
        text-align: justify;
    }
}

.outerContainer {
    display: flex;
    min-height: 100vh;
    .mainContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.editWebsiteBtn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

[media="mobile"] {
    display: none !important;
}
@media (max-width: 500px) {
    [media="desktop"] {
        display: none !important;
    }
    [media="mobile"] {
        display: inline-block !important;
        &.mobileFlex {
            display: inline-flex !important;
            // flex-direction: column;
            width: fit-content;
        }
    }
}
